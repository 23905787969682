import React, { ReactNode } from "react";
import styles from "./Card.module.scss";
import classnames from "classnames/bind";

const cn = classnames.bind(styles);

interface ICard {
  children?: ReactNode;
  hasShadow?: boolean;
  hasRoundCorners?: boolean;
}

export function Card({ children, hasShadow, hasRoundCorners }: ICard) {
  return (
    <div
      className={cn(styles.card, {
        roundCorners: hasRoundCorners,
        shadow: hasShadow
      })}
    >
      {children}
    </div>
  );
}
