import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Styles
import grids from "./grids.module.scss";

// components
import { Topbar } from "./Topbar/Topbar";
const FiberEniig = React.lazy(() => import("./Pages/Fiber/Eniig/fiberEniig"));
const FiberFirst = React.lazy(() => import("./Pages/Fiber/First/fiberFirst"));
const FiberBto = React.lazy(() => import("./Pages/Fiber/Bto/fiberBto"));
const NotFound = React.lazy(() => import("./Pages/404"));

export function Main() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Topbar gridPlaceClass={grids.gridPlaceTopBar} />
      <Switch>
        <Route exact path="/">
          {/*Temporary redirect until the old site is decommissioned */}
          <Redirect to="/fiber/eniig" />
        </Route>
        <Route path="/fiber/eniig">
          <FiberEniig />
        </Route>
        <Route path="/fiber/first">
          <FiberFirst />
        </Route>
        <Route path="/fiber/bto">
          <FiberBto />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}
