declare global {
  interface Window {
    __env__: TEnvironment;
  }
}

const environment = window.__env__ || ({} as TEnvironment);

export type TEnvironment = {
  ENV: string;
  GA_ID: string;
  GTM_ID: string;
  GTM_PREVIEW: string;
  GTM_AUTH: string;
  APP_DYNAMICS_KEY: string;
};

checkEnvVar("ENV", process.env.ENV);
checkEnvVar("GA_ID", process.env.GA_ID);
checkEnvVar("GTM_ID", process.env.GTM_ID);
checkEnvVar("GTM_PREVIEW", process.env.GTM_PREVIEW);
checkEnvVar("GTM_AUTH", process.env.GTM_AUTH);
checkEnvVar("APP_DYNAMICS_KEY", process.env.APP_DYNAMICS_KEY);

function checkEnvVar(key: keyof TEnvironment, processedValue?: string) {
  if (environment[key] === `$${key}`) {
    if (!processedValue) {
      throw Error(`Missing environment variable "${key}" in .env file`);
    }
    environment[key] = processedValue;
  }
}

export default environment;
