import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import environment from "./src/utils/environment";
import { initReactI18next } from "react-i18next";

//import translationEn from "./locales/en/translation.json"; // off until SDU-21310 gets fixed; delete below afterwards
import translationDa from "./locales/da/translation.json";
import fiberEniigDa from "./locales/da/fiberEniig.json";
import fiberFirstDa from "./locales/da/fiberFirst.json";
import fiberBtoDa from "./locales/da/fiberBto.json";
import notFoundDA from "./locales/da/404.json";

const isProduction = environment.ENV === "production";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !isProduction,
    fallbackLng: "da",
    //whitelist: ["en", "da"], // off until SDU-21310 gets fixed; delete below afterwards
    whitelist: ["da"],
    keySeparator: ".",
    returnObjects: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    initImmediate: false,
    resources: {
      // en: {
      //   translations: translationEn  // off until SDU-21310 gets fixed; delete below afterwards
      // },
      da: {
        translations: translationDa,
        fiberEniig: fiberEniigDa,
        fiberFirst: fiberFirstDa,
        fiberBto: fiberBtoDa,
        notFound: notFoundDA
      }
    },
    // have a common namespace used around the full app
    ns: ["translations", "fiberEniig", "fiberFirst", "fiberBto", "notFound"],
    defaultNS: "translations"
  });

export default i18n;
