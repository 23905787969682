$contentWrapperWidth: 48rem; // 768px

.footerTemplate {
  $relativeFr: 2rem; // 24 parts; ensure they all add up
  $leftArea: $relativeFr * 4;
  $leftMiddleArea: $relativeFr * 6;
  $middleArea: $relativeFr * 8;
  $rightMiddleArea: $relativeFr * 4;
  $rightArea: $relativeFr * 2;

  $uid: unique-id(); // grid unique id 
  $gridAreas: 'FooterTitle', 'FooterDescription', 'PhoneNumber', 'ScheduleText', 'Separator', 'TdcLogo',
    'Copyright', 'CookiesLink', 'TdcLink', 'NuudayLogo', 'FacebookLogo', 'LinkedInLogo';

  display: grid;
  grid-template-areas:
    ". FooterTitle_#{$uid} FooterTitle_#{$uid} . . . ."
    ". FooterDescription_#{$uid} FooterDescription_#{$uid} . . . ."
    ". PhoneNumber_#{$uid} PhoneNumber_#{$uid} PhoneNumber_#{$uid} . . ."
    ". ScheduleText_#{$uid} ScheduleText_#{$uid} ScheduleText_#{$uid} . . ."
    ". Separator_#{$uid} Separator_#{$uid} Separator_#{$uid} Separator_#{$uid} Separator_#{$uid} ."
    ". TdcLogo_#{$uid} Copyright_#{$uid} Copyright_#{$uid} CookiesLink_#{$uid} TdcLink_#{$uid} ."
    ". NuudayLogo_#{$uid} . . FacebookLogo_#{$uid} LinkedInLogo_#{$uid} .";
  grid-row-gap: .5rem;
  grid-template-rows: repeat(7, auto);
  grid-template-columns: 1fr $leftArea $leftMiddleArea $middleArea $rightMiddleArea $rightArea 1fr;
  align-items: center;

  @media screen and (max-width: $contentWrapperWidth) {
    grid-template-columns: 3rem 2fr 1fr 6fr 1fr 3rem;
    grid-column-gap: 0;
    grid-row-gap: 0.5rem;
    grid-template-areas:
      ". FooterTitle_#{$uid} FooterTitle_#{$uid} FooterTitle_#{$uid} FooterTitle_#{$uid} ."
      ". FooterDescription_#{$uid} FooterDescription_#{$uid} FooterDescription_#{$uid} FooterDescription_#{$uid} ."
      ". PhoneNumber_#{$uid} PhoneNumber_#{$uid} PhoneNumber_#{$uid} PhoneNumber_#{$uid} ."
      ". ScheduleText_#{$uid} ScheduleText_#{$uid} ScheduleText_#{$uid} ScheduleText_#{$uid} ."
      ". Separator_#{$uid} Separator_#{$uid} Separator_#{$uid} Separator_#{$uid} ."
      ". TdcLogo_#{$uid} TdcLogo_#{$uid} TdcLogo_#{$uid} TdcLogo_#{$uid} ."
      ". Copyright_#{$uid} Copyright_#{$uid} Copyright_#{$uid} Copyright_#{$uid} ."
      ". CookiesLink_#{$uid} TdcLink_#{$uid} . . ."
      ". NuudayLogo_#{$uid} . FacebookLogo_#{$uid} LinkedInLogo_#{$uid} .";
  }

  @each $area in $gridAreas {
    & > .gridPlace#{$area} {
      grid-area: #{$area}_#{$uid};
    }
  }
}