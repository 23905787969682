import React, { ReactNode, Fragment, useState } from "react";
// components
import ExpandDown from "@tdcerhverv/parrotfish/dist/icons/Icons/Expand_down.svg";
// styles
import styles from "./Accordion.module.scss";
import classnames from "classnames/bind";

const cn = classnames.bind(styles);

interface IAccordion {
  title: ReactNode;
  isInitiallyOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  children?: ReactNode;
  className?: string;
}

export function Accordion({
  title,
  isInitiallyOpen,
  onToggle,
  children
}: IAccordion) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const toggle = () => {
    Boolean(onToggle) && onToggle(isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <button
        onClick={toggle}
        className={cn(styles.pallet, {
          active: isOpen
        })}
      >
        <div className={styles.marker} />
        {title}
        <ExpandDown
          className={cn(styles.expandIcon, {
            active: isOpen
          })}
        />
      </button>
      <div
        className={cn(styles.body, {
          active: isOpen
        })}
      >
        {children}
      </div>
    </Fragment>
  );
}
