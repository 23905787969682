import React, { Fragment, ReactNode } from "react";

// Components
import { Heading } from "@tdcerhverv/heading";
import { Paragraph } from "@tdcerhverv/paragraph";

// styles
import styles from "./Steps.module.scss";

export function AccordionTitle({
  text,
  icon
}: {
  text: string;
  icon?: ReactNode;
}) {
  return (
    <Fragment>
      {icon}
      <Heading.H3 className={styles.accordionTitle} weight="strong">
        {text}
      </Heading.H3>
    </Fragment>
  );
}

export function StepHeadline({ children }: { children?: ReactNode }) {
  return (
    <Heading.H3 uppercase weight="strong" className={styles.accordionHeadline}>
      {children}
    </Heading.H3>
  );
}

export function StepParagraph({
  dangerouslySetInnerHTML,
  children
}: {
  dangerouslySetInnerHTML?: { __html: string };
  children?: ReactNode;
}) {
  return (
    <Paragraph
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      className={styles.accordionParagraph}
    >
      {children}
    </Paragraph>
  );
}
