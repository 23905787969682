@import '~@tdcerhverv/parrotfish/dist/colors-design-token.scss';


.marker {
  background-color: map-get($theme, 'primary');
  height: 2.5rem;
  width: 0;
  transition: width .15s ease-in-out;
  margin-right: .75rem;
}

.pallet {
  display: flex;
  align-items: center;
  background-color: map-get($theme, 'white');
  border: 1px solid map-get($theme, 'grey-light');
  width: 100%;
  padding: .75rem 0;
  border-bottom: none;

  &:last-of-type {
    border-bottom: 1px solid map-get($theme, 'grey-light');
  }

  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }

  &.active {
    color: map-get($theme, 'primary');
    
    .marker {
      width: 2px;
    }
  }
}

.body {
  box-sizing: border-box;
  background-color: map-get($theme, 'grey-lightest');
  max-height: 0;
  transition: max-height .25s ease-in-out;
  outline: none;
  box-shadow: none;
  width: 100%;
  overflow: hidden;
  & > ul,
  & > p,
  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  &.active {
    max-height: 50rem;
  }
  & > :first-child {
    margin-top: 1.5rem;
  }
  & > ul:last-of-type {
    margin-bottom: 1.5rem;
  }
}

.expandIcon {
  margin-left: auto;
  transition: transform .25s ease-in-out;
  margin-right: 1.5rem;
  width: 1.5rem;
  height: 1.25rem;

  &.active {
    transform: rotate(180deg);
  }
}
