import React from "react";
import { useTranslation } from "react-i18next";
// components
import { Heading } from "@tdcerhverv/heading";
import { Paragraph } from "@tdcerhverv/paragraph";
import { Icon } from "@tdcerhverv/icon";
import Phone_Landline from "@tdcerhverv/parrotfish/dist/icons/Icons/Phone_landline.svg";
import Facebook_Grey from "assets/images/facebook_grey.svg";
import Linkedin_Grey from "assets/images/linkedin_grey.svg";
import Nuuday_Grey from "assets/images/nuuday_grey.svg";
import TDC_Grey from "assets/images/tdc_logo_grey.svg";
// styles
import styles from "./Footer.module.scss";
import grids from "./Grids.module.scss";
import cx from "classnames";

export function Footer({
  gridPlaceClass,
  translationIdentifier
}: {
  gridPlaceClass: string;
  translationIdentifier: string;
}) {
  const { t } = useTranslation(translationIdentifier);
  const isFiberFirst = /first/i.test(translationIdentifier);

  return (
    <footer
      data-testid="footer"
      className={cx(styles.footer, gridPlaceClass, grids.footerTemplate)}
    >
      <Heading.H4
        className={cx(styles.no_paddingBottom, grids.gridPlaceFooterTitle)}
        lightColored
      >
        {t("footer.title")}
      </Heading.H4>
      <Paragraph
        lightColored
        className={cx(styles.no_marginTop, grids.gridPlaceFooterDescription)}
        dangerouslySetInnerHTML={{ __html: t("footer.text") }}
      />
      <div className={cx(styles.v_center, grids.gridPlacePhoneNumber)}>
        <Icon className={styles.icon} icon={Phone_Landline} size="md" />
        <a href={`tel:${t("footer.phone")}`} className={styles.no_underline}>
          <Heading.H4 className={styles.no_paddingBottom} lightColored>
            {t("footer.phone")}
          </Heading.H4>
        </a>
      </div>
      {!isFiberFirst && (
        <Paragraph
          lightColored
          className={grids.gridPlaceScheduleText}
          dangerouslySetInnerHTML={{ __html: t("footer.openingHours") }}
        />
      )}
      <hr className={cx(styles.separator, grids.gridPlaceSeparator)} />
      <a
        href="https://tdc.dk"
        className={cx(
          styles.tdclogo,
          styles.transparency_hover,
          grids.gridPlaceTdcLogo
        )}
      >
        <Icon icon={TDC_Grey} />
      </a>
      <Paragraph
        lightColored
        className={cx(styles.copyright, grids.gridPlaceCopyright)}
        dangerouslySetInnerHTML={{ __html: t("footer.copyright") }}
      />
      <a
        className={cx(styles.cookieslink, grids.gridPlaceCookiesLink)}
        href="https://tdc.dk/cookiepolitik"
      >
        {t("footer.cookiesPolicyLinkText")}
      </a>
      <a
        className={cx(styles.tdclink, grids.gridPlaceTdcLink)}
        href="https://tdc.dk/"
      >
        {t("footer.TdcLinkText")}
      </a>
      <a
        href="https://nuuday.dk"
        className={cx(
          styles.nuudaylogo,
          styles.transparency_hover,
          grids.gridPlaceNuudayLogo
        )}
      >
        <Icon icon={Nuuday_Grey} size="xxl" />
      </a>
      <a
        href="https://www.facebook.com/TDCErhvervDK"
        className={cx(
          styles.facebooklogo,
          styles.transparency_hover,
          grids.gridPlaceFacebookLogo
        )}
      >
        <Icon icon={Facebook_Grey} />
      </a>
      <a
        href="https://www.linkedin.com/company/tdcerhverv/"
        className={cx(
          styles.linkedinlogo,
          styles.transparency_hover,
          grids.gridPlaceLinkedInLogo
        )}
      >
        <Icon icon={Linkedin_Grey} />
      </a>
    </footer>
  );
}
