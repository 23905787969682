import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";
import TagManager from "react-gtm-module";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import { Main } from "./components/main";

import environment from "src/utils/environment";

TagManager.initialize({
  gtmId: environment.GTM_ID,
  preview: environment.GTM_PREVIEW,
  auth: environment.GTM_AUTH
});

const App = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Main />
      </I18nextProvider>
    </BrowserRouter>
  </Suspense>
);

render(<App />, document.getElementById("root"));
