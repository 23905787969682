@import "~@tdcerhverv/parrotfish/dist/colors-design-token.scss";
$dragOnPreviousBy: 2rem;
$smallScreenWidth: 768px;
$largeScreenWidth: 1700px;
$ultraLargeScreenWidth: 2000px;

.banner {
  color: map-get($theme, "white");
  position: relative;

  .fiberEniigBanner {
    background-image: url("../../../assets/images/fiberEniigBanner.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;

    @media screen and (min-width: $smallScreenWidth) {
      background-position-y: 25%;
      background-size: 70%;
    }
  }

  .fiberBtoBanner {
    background-image: url("../../../assets/images/fiberBtoBanner.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;

    @media screen and (min-width: $smallScreenWidth) {
      background-position-y: 45%;
      background-size: 70%;
    }
  }

  .fiberFirstBanner {
    background-image: url("../../../assets/images/fiberFirstBanner.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;

    @media screen and (min-width: $smallScreenWidth) {
      background-position-y: 25%;
      background-size: 70%;
    }
  }

  .tint {
    color: transparent;
    background: linear-gradient(
      to right,
      rgba(map-get($theme, "primary-dark"), 1) 0%,
      rgba(map-get($theme, "primary-dark"), 1) 30%,
      rgba(map-get($theme, "primary"), 0.8) 50%,
      rgba(map-get($theme, "primary-light"), 1) 70%,
      rgba(map-get($theme, "primary-light"), 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{map-get($theme, "primary-dark")}}', endColorstr='#{map-get($theme, "primary-light")}}',GradientType=1 ); /* IE6-9 */
    opacity: 0.8;

    @media screen and (min-width: $smallScreenWidth) {
      opacity: 1;
    }

    @media screen and (min-width: $ultraLargeScreenWidth) {
      background: linear-gradient(
        to right,
        rgba(map-get($theme, "primary-dark"), 1) 0%,
        rgba(map-get($theme, "primary-dark"), 1) 20%,
        rgba(map-get($theme, "primary"), 0.8) 50%,
        rgba(map-get($theme, "primary-light"), 1) 80%,
        rgba(map-get($theme, "primary-light"), 1) 100%
      );
    }
  }

  .bannerHeadline {
    position: relative;
    margin-top: 3.75rem;
    padding-bottom: 1.5rem;
  }

  .bannerText {
    position: relative;
    padding-bottom: $dragOnPreviousBy;
    margin-top: 0;
  }
}
