@import "~@tdcerhverv/parrotfish/dist/colors-design-token.scss";

$contentWrapperWidth: 768px;
$dragOnPreviousBy: 2rem;

.steps {
  position: relative;
  box-shadow: 0 0 4px 2px rgba(map-get($theme, "grey-dark"), 0.25);
  margin-top: -#{$dragOnPreviousBy};

  & > div:not(:last-child) {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > div:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  @media screen and (max-width: $contentWrapperWidth) {
    margin-top: 2rem;
  }
}

.cardTitle {
  font-size: 1.125rem;
  padding-bottom: 1rem;
}

.accordionTitle {
  color: inherit;
  padding-bottom: 0;
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: .875rem;
}

.accordionParagraph {
  margin: 0;
}

.accordionIcon {
  width: 1.5rem;
  height: 1.5rem;
  fill: map-get($theme, 'grey-medium-dark');
}

.accordionHeadline {
  font-size: .875rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
}
