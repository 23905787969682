import React from "react";
// components
import TDC from "assets/images/tdc_logo.svg";
import { Icon } from "@tdcerhverv/icon";
// styles
import styles from "./Topbar.module.scss";
import cx from "classnames";

export function Topbar({ gridPlaceClass }: { gridPlaceClass?: string }) {
  return (
    <header data-testid="top-bar" className={cx(styles.topbar, gridPlaceClass)}>
      <Icon icon={TDC} />
    </header>
  );
}
