import React, { ReactElement } from "react";
import { TFunctionResult } from "i18next";
import { StepParagraph } from "./Steps";

export const StepList = ({
  bulletpoints
}: {
  bulletpoints: TFunctionResult;
}): ReactElement => {
  try {
    return (
      <ul>
        {(bulletpoints as string[]).map((bulletpoint, i) => (
          <li key={i}>
            <StepParagraph
              dangerouslySetInnerHTML={{
                __html: bulletpoint
              }}
            />
          </li>
        ))}
      </ul>
    );
  } catch (e) {
    console.warn("Given element is not an array", bulletpoints, e);
    return <div />;
  }
};
