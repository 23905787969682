@import "~@tdcerhverv/parrotfish/dist/colors-design-token.scss";

.card {
  background-color: map-get($theme, 'white');
  border: 1px solid map-get($theme, 'grey-light');
  padding: 1.5rem 3rem;
}

.roundCorners {
  border-radius: 2px;
}

.shadow {
  box-shadow: 0 0 1rem 0 rgba(map-get($theme, 'grey-light'), 0.25);
}
