import React from "react";
import { useTranslation } from "react-i18next";
// components
import { Heading } from "@tdcerhverv/heading";
import { Paragraph } from "@tdcerhverv/paragraph";
// styles
import cx from "classnames";
import styles from "./Banner.module.scss";
import grids from "./Grids.module.scss";

export function Banner({
  gridPlaceClass,
  translationIdentifier
}: {
  gridPlaceClass: string;
  translationIdentifier: string;
}) {
  const { t } = useTranslation(translationIdentifier);
  const isFiberFirst = translationIdentifier === "fiberFirst";
  const isFiberEniig = translationIdentifier === "fiberEniig";
  const isFiberBto = translationIdentifier === "fiberBto";

  return (
    <section
      data-testid="banner"
      className={cx(styles.banner, gridPlaceClass, grids.bannerTemplate)}
    >
      <div
        className={cx(grids.gridPlaceBannerTint, {
          [styles.fiberFirstBanner]: isFiberFirst,
          [styles.fiberEniigBanner]: isFiberEniig,
          [styles.fiberBtoBanner]: isFiberBto
        })}
      />
      <div className={cx(styles.tint, grids.gridPlaceBannerTint)}>tint</div>
      <Heading.H1
        lightColored
        className={cx(styles.bannerHeadline, grids.gridPlaceBannerHeadline)}
      >
        {t("banner.introduction")}
      </Heading.H1>
      <Paragraph
        lightColored
        className={cx(styles.bannerText, grids.gridPlaceBannerText)}
      >
        {t("banner.description")}
      </Paragraph>
    </section>
  );
}
