import React from "react";
import styles from "./Usabilla.module.scss";
import cx from "classnames";

export function Usabilla({ gridPlaceClass }: { gridPlaceClass?: string }) {
  return (
    <div
      data-testid="usabilla"
      ub-in-page="5e25aca01a8e9e17266541b4"
      className={cx(styles.usabillaContainer, gridPlaceClass)}
    />
  );
}
