$contentWrapperWidth: 48rem; // 768px

.bannerTemplate {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr $contentWrapperWidth 1fr;

  @media screen and (max-width: $contentWrapperWidth) {
    grid-template-columns: 3rem auto 3rem;
  }

  & > .gridPlaceBannerTint {
    grid-row: 1 / span 2;
    grid-column: 1 / span 3;
  }

  & > .gridPlaceBannerHeadline {
    grid-row: 1;
    grid-column: 2;
  }

  & > .gridPlaceBannerText {
    grid-column: 2;
    grid-row: 2;
  }
}
