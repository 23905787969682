@import "~@tdcerhverv/parrotfish/dist/colors-design-token.scss";

$contentWrapperWidth: 768px;

.footer {
  background: map-get($theme, "black");
  color: map-get($theme, "white");
  margin-top: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 1.5rem;
  align-items: center;

  a {
    text-decoration: underline;
    color: map-get($theme, "primary");
    font-weight: 100;

    &.no_underline {
      text-decoration: none;
    }

    &.transparency_hover {
      transition: opacity .25s ease-in-out;

      &:hover {
        opacity: .5;
      }
    }
  }
}

.icon {
  fill: currentColor;
  margin-right: 0.5rem;
}

.v_center {
  display: flex;
  align-items: center;
}

.no_paddingBottom {
  padding-bottom: 0;
}

.no_marginTop {
  margin-top: 0;
}

.tdclogo {
  display: block;

  & > svg {
    width: 7rem;
    height: 2.5rem;
  }
}

.separator {
  margin-bottom: 1.5rem;
}

.copyright {
  margin: 0 auto;

  @media screen and (max-width: $contentWrapperWidth) {
    margin-top: 0.5rem;
    margin-left: 0;
  }
}

.cookieslink {
  display: block;
  margin-left: auto;
  margin-right: 1rem;

  @media screen and (max-width: $contentWrapperWidth) {
    margin-top: 1rem;
    margin-left: 0;
  }
}

.tdclink {
  display: block;

  @media screen and (max-width: $contentWrapperWidth) {
    margin-top: 1rem;
  }
}

.nuudaylogo {
  margin-top: 2rem;
  display: block;
  
  & > svg {
    width: 7rem;
    height: 2.5rem;
  }

  @media screen and (max-width: $contentWrapperWidth) {
    margin-top: 1.5rem;
  }
}

.facebooklogo {
  margin-top: 2rem;
  display: block;
  margin-left: auto;
  margin-right: 1rem;

  & > svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.linkedinlogo {
  margin-top: 2rem;
  display: block;
  
  & > svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  @media screen and (max-width: $contentWrapperWidth) {
    margin-left: auto;
  }
}
